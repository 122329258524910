<template>
  <div class="success-tips">
    <van-nav-bar :title="$t('title.success')" left-arrow @click-left="goBack" :border="false" />
    <div class="success-tips-content">
      <img class="success-tips-icon" src="/images/success.png" />
      <div class="success-tips-text">{{$t(`status.${type}Success`)}}</div>
      <van-button
        size="large"
        type="primary"
        style="margin-bottom: 16px;"
        block
        @click="goBack"
      >{{$t('button.continueWithdraw')}}</van-button>
      <van-button
        type="primary"
        size="large"
        plain
        block
        @click="goToHome"
      >{{$t('button.back')}}</van-button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
  
export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    return {
      goBack,
    };
  },
  
  data() {
    return {
      type: this.$route.params.type || '',
    };
  },

  mounted() {
  },
  
  methods: {
    goToHome() {
      this.$router.go(-2);
    },
  },
};
</script>

<style lang="less">
.success-tips {
  .success-tips-content {
    text-align: center;
    padding: 16px;
    .success-tips-icon {
      width: 110px;
      margin: 40px auto 12px;
    }
    .success-tips-text {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}
</style>
